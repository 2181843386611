<template>
    <!-- Add Webinar dialog -->
    <ws-dialog
      v-if="displayDialog"
      v-model="displayDialog"
      :title="$t('webinar.main.dialog.title')"
      @close="$emit('set-display-dialog', false); "
      fullscreen
      not-centered
      no-padding
      hide-buttons
      no-navigation
      @get-default-content-container="contentContainer = $event"
      :loading="loading"
    >
      <template #header>
        <div
          class="d-flex align-center justify-space-between pt-5 px-10 pb-8"
        >
          <div class="d-flex align-center justify-start" style="column-gap: 10px; width: 33%">
            <ws-logo title="webinar.main.header"/>
            <h5 :style="`color: ${wsDARKLIGHT}; border: 1px solid ${wsDARKLIGHT}`" class="rounded-xl px-2 py-1" > {{ $t(`webinar.main.${entityData.status}`) }}</h5>
          </div>

          <h2 style="font-size: 22px; width: 33%;" class="text-center"> {{ step === 1 ? $t('webinar.settings.settings_title') : $t('webinar.settings.members_moderators') }}</h2>

          <span style="width: 33%" class="text-right">
            <v-btn
              @click="$emit('set-display-dialog', false)"
              class="mr-n2"
              :color="wsDARKER"
              icon
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </span>
        </div>
        <div style="width: 812px; margin: auto">
          <portal-target name="webinar-settings-header" />
        </div>
      </template>

      <template #default>
        <!-- Main webinar info (STEP 1) -->
        <div
          v-if="step === 1"
          style="max-width: 600px" class="ma-auto"
        >
          <ws-text-field
            v-model="entityData.name"
            :label="$t('webinar.main.dialog.name')"
            class="mb-6"
            :placeholder="$t('webinar.settings.create_name')"
          />
          <image-param-picker
              v-model="entityData.image"
              class="mb-6"
              no-border
              disable-portal
          >
            <template>
              <div :style="`color: ${wsACCENT};`" >
                <h5 class="mb-1 mt-4" >{{ $t('webinar.settings.upload_file') }}</h5>
                <h5 class="font-weight-regular" >{{ $t('webinar.settings.upload_size') }}</h5>
              </div>
            </template>
          </image-param-picker>

          <ws-text-field
            v-model="entityData.description"
            :label="$t('ShortDescription')"
            class="mb-2"
            :placeholder="$t('EnterShortDescription')"
            counter="100"
            area
          />


          <h5 class="font-weight-regular wsACCENT mb-2">
            {{ $t('webinar.settings.description_label') }}
          </h5>
          <v-sheet min-height="200" :style="`border : 1px solid ${wsDARKLIGHT}`" class="wsRoundedLight pt-3  pb-7 mb-6">
            <ws-text-editor
                v-model="entityData.full_description"
                is-public
            />
          </v-sheet>

          <div class="d-flex align-center justify-space-between mb-6" style="column-gap: 16px">
            <ws-date-picker
              v-model="entityData.date"
              :placeholder="$t('webinar.main.dialog.date_start_action')"
              :label="$t('webinar.main.dialog.date_start')"
              style="width: 50%"
              :min="$time().YMD()"
              :error="dateError"
              @input="dateError = false; "

            />
            <ws-select
              v-model="entityData.time"
              :items="timeSelect"
              :placeholder="$t('webinar.settings.time')"
              :label="$t('webinar.settings.time_action')"
              style="width: 50%"
              hide-details
              :error="dateError"
              @input="dateError = false; "
            />
          </div>

          <ws-accordion
            :items="navigationItems"
            class="mb-6"
          >
            <template #action.paid>

              <ft-select
                v-model="entityData.paid"
                :items="paymentOptionsSelect"
                class="pointer"
              >
                <div style="min-width: 150px" class="d-flex justify-end">
                  <v-chip
                    :style="entityData.paid ? 'opacity: 1' :  'opacity: 0.5' "
                    dark
                    :color="wsACCENT"
                    class="px-1 justify-end font-weight-medium pointer mr-5"
                  >
                    <h5 class="mx-2">{{ convertValueToText(entityData.paid, paymentOptionsSelect) }}</h5>
                    <v-icon>mdi-menu-down</v-icon>
                  </v-chip>
                </div>
              </ft-select>
            </template>
            <template #action.access>
              <ws-tooltip top :text="$t('webinar.settings.access_tooltip')">
                <v-icon>mdi-information-outline</v-icon>
              </ws-tooltip>

              <ft-select
                v-model="entityData.access"
                :items="webinarAccessSelect"
                class="pointer"
              >

                <div style="min-width: 150px" class="d-flex justify-end">

                  <v-chip
                    :style="entityData.access ? 'opacity: 1' :  'opacity: 0.5' "
                    dark
                    :color="wsACCENT"
                    class="px-1 justify-end font-weight-medium pointer mr-5"
                  >
                    <h5 class="mx-2">{{ convertValueToText(entityData.access, webinarAccessSelect) }}</h5>
                    <v-icon>mdi-menu-down</v-icon>
                  </v-chip>
                </div>

              </ft-select>
            </template>
            <template #action.price >

              <ws-tooltip v-if="!entityData.price || entityData.price === 0" top :text="$t('webinar.notify.no_cost_error')">
                <v-icon :color="wsWARNING">mdi-alert-octagon-outline</v-icon>
              </ws-tooltip>

              <div style="position: relative" class="mr-4">
                <ws-text-field
                  v-model.number="entityData.price"
                  width="110"
                  class="pa-2"
                  placeholder="0"
                  number
                />
                <h5 style="position: absolute; top: 32%; right: 16%; z-index: 999999;
                background-color: #fff; height: 25px" class="font-weight-regular"> грн </h5>
              <!--   подставить BUSINESS_CURRENCY -->
              </div>

            </template>
          </ws-accordion>

          <!--  Show/Hide additional settings  -->
          <ws-button
            @click="showHideActions"
            :label="showHideAdditionalSettings ? $t('webinar.settings.additional_settings') : $t('webinar.settings.hide_additional_settings')"
            outlined
            :leftIcon="showHideAdditionalSettings ? 'mdi-chevron-down' : 'mdi-chevron-up'"
            class="mb-6"
            :color="wsACCENT"
          />


          <v-expand-transition>


            <ws-accordion
              v-if="!showHideAdditionalSettings"
              :items="navigationAdditionalItems"
              class="mb-6"
            >

              <template #action.scripts="{forcedExpand}" >

                <ws-tooltip top :text="$t('webinar.notify.no_script_text_tooltip')">
                  <v-icon v-if="isAddScript && entityData.scripts.content.some(el => el.text === '') && entityData.scripts_enabled" :color="wsWARNING">mdi-alert-octagon-outline</v-icon>
                </ws-tooltip>

                <ft-select
                  v-model="entityData.scripts_enabled"
                  :items="webinarScriptStatesSelect"
                  class="pointer"
                  @input="openScriptDialog(forcedExpand)"
                >

                  <div style="min-width: 150px" class="d-flex justify-end" >

                    <v-chip
                      :style="entityData.scripts_enabled ? 'opacity: 1' :  'opacity: 0.5' "
                      dark
                      :color="wsACCENT"
                      class="px-1 justify-end font-weight-medium pointer mr-5"
                    >

                      <h5 class="mx-2">{{ convertValueToText(entityData.scripts_enabled, webinarScriptStatesSelect) }}</h5>
                      <v-icon>mdi-menu-down</v-icon>
                    </v-chip>
                  </div>

                </ft-select>
              </template>
              <template #action.time>
                <ft-select
                  v-model="entityData.time_before"
                  :items="webinarTimeBeforeSelect"
                  class="pointer"
                >

                  <div style="min-width: 150px" class="d-flex justify-end">

                    <v-chip
                      dark
                      :color="wsACCENT"
                      class="px-1 justify-end font-weight-medium pointer mr-5"
                    >
                      <h5 class="mx-2">{{ convertValueToText(entityData.time_before, webinarTimeBeforeSelect) }}</h5>
                      <v-icon>mdi-menu-down</v-icon>
                    </v-chip>
                  </div>

                </ft-select>
              </template>
              <template #action.language>
                <ws-select
                  width="125px"
                  v-model="entityData.lang"
                  :items="LANGUAGES_SELECT"
                  autocomplete
                  class="pointer mr-4"
                />
              </template>
              <template #action.participants>
                <ft-select
                  v-model="entityData.show_participants"
                  :items="webinarScriptStatesSelect"
                  class="pointer"
                >

                  <div style="min-width: 150px" class="d-flex justify-end">

                    <v-chip
                      dark
                      :color="wsACCENT"
                      :style="entityData.show_participants ? 'opacity: 1' :  'opacity: 0.5' "
                      class="px-1 justify-end font-weight-medium pointer mr-5"
                    >

                      <h5 class="mx-2">{{ convertValueToText(entityData.show_participants, webinarScriptStatesSelect) }}</h5>
                      <v-icon>mdi-menu-down</v-icon>
                    </v-chip>
                  </div>

                </ft-select>
              </template>

              <!-- Accordeon Script Item -->
              <template #item.scripts v-if="entityData.scripts_enabled">

                <div v-if="!isAddScript && entityData.scripts.content.every(el => el.remove)" class="text-center py-16 px-5" :style="`padding: 53px 74px; color: ${wsACCENT}`" >
                  <v-icon size="48" class="mb-2" :color="wsDARKLIGHT">mdi-file-document</v-icon>
                  <h5 class="font-weight-regular mb-2">{{$t('webinar.settings.script_details')}}</h5>
                  <ws-button :label="$t('webinar.settings.add_notes')" @click="openAddScripts" />
                </div>

                <div v-else class="py-1 px-5">

                  <p class="mb-4" :style="`color: ${wsACCENT}; font-size: 13px; font-weight: 400`">{{ $t('webinar.settings.lecture_time')}}: {{finalTime}}</p>

                  <!--    Scripts draggable component  -->
                  <ws-draggable-note
                    ref="draggable"
                    v-model="entityData"
                    :scripts="scriptsDraggable"
                    @update-add-script="isAddScript = $event"
                    @update-script-items="entityData.scripts.content = $event"
                  />

                </div>
              </template>
            </ws-accordion>
          </v-expand-transition>

        </div>

        <!-- Members & Moderators (STEP 2) -->
        <div v-else style="max-width: 812px; margin: auto" >
          <!--   Table header (members & moderators)  -->
          <portal to="webinar-settings-header">
            <div class="d-flex align-center justify-space-between mb-4">
              <ws-button-group
                v-model="buttonGroupValue"
                style="width: 214px"
                :items="buttonGroupItems"
              />

              <div class="d-flex align-center justify-space-between">

                <ws-search-filters :items="webinarPaymentFilter" class="mr-2"/>
                <ft-select
                  @expand="buttonGroupValue === 'participants' ? selectedItem = item : false"
                  :items="createMembersActions"
                  left-icons
                >

                  <div>
                    <ws-button
                      :label="buttonGroupValue === 'participants' ? $t('webinar.settings.add_members') : $t('webinar.settings.add_moderators')"
                      left-icon="mdi-plus"
                      :click-stop="buttonGroupValue !== 'participants'"
                      @click="buttonGroupValue === 'moderators' ? openCreateModeratorDialog() : false"
                    />
                  </div>

                </ft-select>

              </div>
            </div>
          </portal>

          <!--   Table (members & moderators)   -->
          <div  :style="`border: 1px solid ${wsBACKGROUND}; height: 75vh; width: 100%`" class="d-flex flex-column rounded">

            <ws-data-table
              style="height: 100%; border: 1px solid var(--wsBACKGROUND); "
              :headers="buttonGroupValue === 'participants' && entityData.paid ? headers : headers.filter(el => el.value !== 'paid')"
              :items="buttonGroupValue === 'participants' ? participantsItems : moderatorItems"
              selectable
              footer-portal="participants"
              :search="filtersData.search || null"
              :selected-rows="buttonGroupValue === 'participants' ? selectedParticipants : selectedModerators"
              @select="buttonGroupValue === 'participants' ? selectedParticipants = $event.items : selectedModerators = $event.items"
              :multiple-actions="multipleActionsSelect"
            >


              <template #item.name="{item}">
                <h5>{{ item.lastname }} {{ item.name }}</h5>
              </template>

              <template #item.paid="{item}">

                <ws-tooltip :text="item.paid ? $t('webinar.settings.tooltip_payment_yes') : $t('webinar.settings.tooltip_payment_no')" top>
                  <ws-switch v-model="item.paid" @input="savePaymentChanges(item)"></ws-switch>
                </ws-tooltip>
              </template>

              <template #item.delete="{item}">
                <ws-tooltip :text="$t('webinar.settings.tooltip_delete')" top>
                  <v-icon @click="buttonGroupValue === 'participants' ? removeParticipants(item) : removeModerators(item)">mdi-delete-outline</v-icon>
                </ws-tooltip>
              </template>

              <!--  Main content (if no table data)  -->
              <template #footer.prepend>
                <div v-if="(buttonGroupValue === 'participants' && !participantsItems.length) || (buttonGroupValue === 'moderators' && !moderatorItems.length )" style="height: 60vh; width: 100%">
                  <div :style="`max-width: 522px; overflow: hidden; height: 100%; margin: auto; color: ${wsDARKER}`" class="d-flex flex-column justify-center align-center text-center">
                    <v-icon class="mb-4" size="84" :color="`${wsDARKLIGHT}`">mdi-account-multiple</v-icon>
                    <h3 class="mb-4" >{{ buttonGroupValue === 'participants' ? $t('webinar.settings.check_members') : $t('webinar.settings.check_moderators') }} </h3>
                    <p>{{ buttonGroupValue === 'participants' ? $t('webinar.settings.check_members_description') : $t('webinar.settings.check_moderators_description') }}</p>
                  </div>
                </div>
              </template>
            </ws-data-table>

            <div class="flex-grow-1" ></div>
            <portal-target name="participants" class="pl-4 py-2"></portal-target>
          </div>
        </div>
          <!--   Create member dialog   -->
          <ws-user-edit-dialog
            :display="displayCreateMemberDialog"
            @save="sendAddMemberForm()"
            @close="displayCreateMemberDialog = false"
            v-model="entityDataCreateMember"
            :title="$t('webinar.settings.new_member_title')"
          >

            <ws-select
              v-model="entityDataCreateMember.activity_status"
              :items="webinarScriptStatesSelect"
              :label="$t('webinar.settings.member_info.activity_status')"
              class="mt-5"
              hide-details
              avalon-style
            />
            <ws-date-picker
              v-model="entityDataCreateMember.registration_date"
              :label="$t('webinar.settings.member_info.register')"
              class="mt-5"
              avalon-style
            />
          </ws-user-edit-dialog>

          <!--   Import members dialog   -->
          <ws-dialog
            v-model="displayImportMembersDialog"
            fullscreen-align="right"
            :title="$t('webinar.settings.member_info.import_members_title')"
            fill-height
            not-centered
            border-radius="0"
            :save-text="$t('Add')"
            :width="stepDialog === 1 ? '600' : '764'"
          >
            <template #default>
              <p style="font-weight: 350; margin-bottom: 13px" >
                {{ stepDialog === 1 ? $t('webinar.settings.member_info.import_members_description') : $t('webinar.settings.import_finished')}}
              </p>
              <!--  Import file (uploader)  -->
              <div v-if="stepDialog === 1">
                <ws-file-uploader
                  v-model="entityDataImportMember.image"
                  no-border
                  disable-portal
                  style="margin-top: 13px"
                  xls
                >
                  <template>
                    <div :style="`color: ${wsACCENT};`" >
                      <v-icon size="32" :color="wsATTENTION" class="mb-4">mdi-upload-circle</v-icon>
                      <h5  style="max-width: 250px; margin: auto">{{ $t('webinar.settings.member_info.upload_file_text') }}</h5>
                    </div>
                  </template>
                </ws-file-uploader>
              </div>

              <!--  Imported file info  -->
              <div v-else>
                <div class="d-flex align-center justify-space-between" :style="`color: ${wsACCENT}; column-gap: 16px; margin-bottom: 17px`">
                  <div v-for="(item, index) in importParticipantsItems" :key="item.text + index" class="rounded-lg pa-4" :style="`width: 33.333%; border: 1px solid ${wsBACKGROUND}; margon-bottom: 17px`">
                    <v-icon class="mb-1">{{ item.icon }}</v-icon>
                    <h5 class="mb-1" style="font-size: 12px">{{ item.text }}</h5>
                    <h3 >{{ item.value }}</h3>
                  </div>

                </div>

                <h5 class="mb-2">{{ $t('webinar.settings.new_users') }}</h5>

                <!--  Imported file results  -->
                <ws-data-table
                  :headers="headersImportTable"
                  :items="importedMembersItems"
                  no-footer
                  class="rounded-lg mb-6"
                  :style="`border: 1px solid ${wsBACKGROUND}`"
                >
                  <template #item.index="{item, index}">
                    <h5>{{ index + 1 }}</h5>
                  </template>

                </ws-data-table>

                <h5 class="mb-2">{{ $t('webinar.settings.import_table_errors') }}</h5>

                <!--  Imported file errors  -->
                <ws-data-table
                  :headers="headersImportTableError"
                  :items="importedMembersItems"
                  no-footer
                  no-header
                  class="rounded-lg mb-6"
                  :style="`border: 1px solid ${wsBACKGROUND}`"
                >
                  <template #item.index="{item, index}">
                    <h5>{{ index + 1 }}</h5>
                  </template>

                  <template #item.error_text>
                    <h5>{{ $t('webinar.settings.import_table_error_title') }}:</h5>
                  </template>

                  <template #item.error_value>
                    <h5>email</h5>
                  </template>

                </ws-data-table>

              </div>

            </template>

            <template #buttons>
              <div v-if="stepDialog === 1" class="d-flex align-center justify-space-between" style="width: 552px">
                <ws-button :label="$t('webinar.settings.member_info.upload_template')" outlined left-icon="mdi-upload" :color="wsACCENT" @click="stepDialog++"/>
                <div>
                  <ws-button outlined :label="$t(`Cancel`)" class="mr-2" @click="displayImportMembersDialog = false"/>
                  <ws-button :label="$t(`Add`)" />
                </div>
              </div>
            </template>
          </ws-dialog>

          <!--  Add members from list dialog -->
          <ws-user-select-dialog
            student
            v-if="displayAddMembersFromList"
            v-model="entityDataCreateMember"
            :headers="headers"
            :display-dialog="displayAddMembersFromList"
            :webinar="createdWebinar"
            :title-dialog="$t('webinar.settings.add_members')"
            :subtitle="$t('webinar.settings.create_member_title')"
            :table-title="$t('webinar.settings.new_member_description')"
            :create-new-user-label="$t('webinar.settings.create_member_button')"
            @close="displayAddMembersFromList = false"
            @save="(event, userList) => saveNewMemberListChanges(event, userList)"
            @open-create-user-dialog="openCreateMemberDialog"
          >
            <template #action="{entityData}">
              <ws-switch class="ml-6 mt-0" v-model="entityData.paid" :placeholder="$t('webinar.settings.add_payed_members_label')"></ws-switch>
            </template>
          </ws-user-select-dialog>

          <!--  Create moderator dialog   -->
          <ws-user-select-dialog
            manager
            v-model="entityDataCreateModerator"
            :display-dialog="displayCreateModeratorDialog"
            :headers="headers"
            :title-dialog="$t('webinar.settings.add_moderators')"
            :table-title="$t('webinar.settings.new_moderator_description')"
            :subtitle="$t('webinar.settings.create_moderator_title')"
            :create-new-user-label="$t('webinar.settings.create_moderator_profile')"
            :description="$t('webinar.settings.create_moderator_description')"
            :managers="managers"
            @open-create-user-dialog="openCreateModeratorDialog"
            @close="displayCreateModeratorDialog = false"
            @save="(event, userList) => saveNewModeratorListChanges(event, userList)"
          />
      </template>

      <template #footer>
        <div class="d-flex justify-space-between align-center px-8 py-4" :style="`background-color: ${wsLIGHTCARD}`">
          <ws-button
            outlined
            :label="$t('webinar.settings.back')"
            @click="goBack"
          />

          <div class="d-flex justify-space-between align-center">
            <h5
              :style="`color: ${step === 1 ? wsACCENT : wsATTENTION}; cursor: ${step === 1 ? 'auto' : 'pointer'}`"
              @click="step === 2 ? goBack() : false"
            >
              {{ $t('webinar.settings.settings_title') }}
            </h5>
            <v-icon :style="`color: ${step === 1 ? wsACCENT : wsATTENTION}`">mdi-chevron-right</v-icon>
            <h5 :style="`color: ${step === 1 ? wsDARKLIGHT : wsACCENT}`">{{ $t('webinar.settings.members_moderators') }}</h5>
          </div>

          <div>
            <ws-button
              outlined
              :label="$t('webinar.settings.finish_later')"
              class="mr-2"
              :disabled="step === 1"
              @click="saveDraft"
            />
            <ws-button
              v-if="!onlyEdit"
              :disabled="!isValidWebinarInfo && step === 1"
              :label="step === 1 ? $t('Next') : $t('webinar.settings.publish')"
              @click="nextPublishAction"
            />
            <ws-button
              v-else
              :disabled="!isValidWebinarInfo"
              :label="step === 1 ? $t('Next') : $t('webinar.settings.save')"
              @click="saveEditedWebinar"
            />
          </div>
        </div>
      </template>

    </ws-dialog>



</template>

<script>
import {mapActions} from "vuex";
import ImageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker.vue";
import languagesList from "@/mixins/languagesList";
import wsSearchFilters from "@/components/pages/dashboard/UI/wsSearchFilters.vue";
import WsDraggableNote from "@modules/webinars/components/UI/wsDraggableNote.vue";
import WsUserEditDialog from "@/components/UI/userEditDialog/wsUserEditDialog.vue";
import WsUserSelectDialog from "@modules/webinars/components/UI/wsUserSelectDialog.vue";

export default {
  name: "WebinarSettings.vue",
  mixins : [languagesList],
  components: {
    WsUserSelectDialog,
    WsUserEditDialog,
    WsDraggableNote,
    ImageParamPicker,
    wsSearchFilters
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    displayDialog: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ''
    },
    isNewWebinar: {
      type: Boolean,
      default: true
    },
    onlyEdit: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      limit: 100,
      managersList: [],
      loading: false,
      dateError: false,
      entityData: {},
      contentContainer: null,
      importedMembersItems: [
        { name: 'Some name', email: 'email@gmail.com', password: 'dfg5rjh', phone: '+38 096 125 12 12'},
        { name: 'Some name 2', email: '2email@gmail.com', password: 'ssdfvgh', phone: '+38 096 125 13 11'},
      ],
      importedMembersErrors: [],
      selectedParticipants: [],
      selectedModerators: [],
      selectedManagers: [],
      paymentChangeParticipants: [],
      selectedMembersFromList: [],
      entityDataCreateMember: {
        activity_status: 'active',
        selectedUsers: []
      },
      entityDataImportMember: {},
      isCheckedModerator: false,
      isCheckedMember: false,
      displayImportMembersDialog: false,
      displayCreateMemberDialog: false,
      displayAddMembersFromList: false,
      displayCreateModeratorDialog: false,
      entityDataCreateModerator: {
        selectedUsers: []
      },
      selectedItem: {},
      filtersData: {},
      headersScripts:[
        { text: "", value: "content" },
      ],
      participantsItems: [],
      moderatorItems: [],
      buttonGroupValue: 'participants',
      stepDialog: 1,
      isAddScript: false,
      showHideAdditionalSettings: true,
      createdWebinar: {}
    }
  },
  computed: {
    paymentOptionsSelect() {
      return [
        { text: this.$t('webinar.main.dialog.free') , value: false },
        { text: this.$t('webinar.main.dialog.paid') , value: true },
      ]
    },
    timeSelect() {
      return this.$time().setTime(this.entityData.date).isToday ? this.TIME_SELECT_NOW_15 : this.TIME_SELECT_15
    },
    scriptsDraggable() {
      return this.entityData.scripts.content.some(el => el.remove) || this.entityData.scripts.content.length ? this.entityData.scripts.content : [{ text: ``, duration: 10 }]
    },
    headersImportTableError() {
      return [
        { text: '', value: 'index', sortable: false, width: 10 },
        { text: '', value: 'error_text' },
        { text: '', value: 'error_value' },
        { text: '', value: '', sortable: false, width: '50%' },
      ]
    },
    headersImportTable() {
      return [
        { text: '', value: 'index', sortable: false, width: 10 },
        { text: this.$t('webinar.settings.full_name'), value: 'name' },
        { text: this.$t('webinar.settings.email'), value: 'email' },
        { text: this.$t('webinar.settings.member_info.password'), value: 'password' },
        { text: this.$t('webinar.settings.phone'), value: 'phone' },
      ]
    },
    importParticipantsItems() {
      return [
        { text: this.$t('webinar.settings.new_users'), icon: 'mdi-account-multiple', value: 5 },
        { text: this.$t('webinar.settings.data_errors'), icon: 'mdi-alert-octagon-outline', value: 1 },
        { text: this.$t('webinar.settings.record_errors'), icon: 'mdi-information-outline', value: 1 },
      ]
    },
    createMembersActions() {
      return [
        {
          text : this.$t('webinar.settings.choose_clients'),
          value : 'choose_clients',
          icon: 'mdi-account-multiple',
          action : () => { this.openAddMembersFromListDialog() }
        },
        {
          text : this.$t('webinar.settings.create_member'),
          value : 'create_member',
          icon: 'mdi-account-plus',
          action : () => { this.openCreateMemberDialog() }
        },
        {
          text : this.$t('webinar.settings.import_file'),
          value : 'import_file',
          icon: 'mdi-table-arrow-left',
          action : () => { this.openImportMembersDialog() }
        },
      ]
    },
    headers() {
      return [
        { text: this.$t('webinar.settings.full_name'), value: 'name' },
        { text: this.$t('webinar.settings.email'), value: 'email' },
        { text: this.$t('webinar.settings.phone'), value: 'phone' },
        { text: this.$t('webinar.main.table.payment'), value: 'paid', width: 10 },
        { text: '', value: 'delete', sortable: false, width: 10 },
      ]
    },
    buttonGroupItems() {
      return [
        {text: this.$t('webinar.settings.members'), value: 'participants'},
        {text: this.$t('webinar.settings.moderators'), value: 'moderators'},
      ]
    },
    webinarAccessSelect() {
      return [
        { text: this.$t('webinar.settings.hidden'), value: false },
        { text: this.$t('webinar.settings.visible'), value: true }
      ]
    },
    navigationItems() {
      let items = [
        { name : this.$t('webinar.settings.payment_title'),
          subtitle : this.$t('webinar.settings.payment_subtitle'),
          value : 'paid' ,
          icon : 'mdi-currency-usd',
          expand : false,
          noExpand: true,
          expanded : false
        },
        { name : this.$t('webinar.settings.access_title') ,
          subtitle : this.$t('webinar.settings.access_subtitle'),
          value : 'access' ,
          icon : 'mdi-eye',
          expand : false,
          noExpand: true,
          expanded : false
        },
      ]
      if (this.entityData.paid) {
        items.splice(1,0, {
          name : this.$t('webinar.settings.cost_title'),
          subtitle : this.$t('webinar.settings.cost_subtitle'),
          value : 'price' ,
          icon : 'mdi-wallet',
          expand : false,
          noExpand: true,
          expanded : false
        })
      }
      return items
    },
    navigationAdditionalItems() {
      return [
        { name : this.$t('webinar.settings.script_title'),
          subtitle : this.$t('webinar.settings.script_subtitle'),
          value : 'scripts' ,
          icon : 'mdi-file-document',
          expand : false,
          noExpand: true,
          expanded : this.entityData.scripts_enabled
        },
        { name : this.$t('webinar.settings.time_before_title'),
          subtitle : this.$t('webinar.settings.time_before_subtitle'),
          value : 'time' ,
          icon : 'mdi-timer-outline',
          expand : false,
          noExpand: true,
          expanded : false
        },
        { name : this.$t('webinar.settings.language_title'),
          subtitle : this.$t('webinar.settings.language_subtitle'),
          value : 'language' ,
          icon : 'mdi-web',
          expand : false,
          noExpand: true,
          expanded : false
        },
        { name : this.$t('webinar.settings.members_title'),
          subtitle : this.entityData.show_participants
            ? this.$t('webinar.settings.members_subtitle')
            : this.$t('webinar.settings.members_hide_subtitle'),
          value : 'participants' ,
          icon : 'mdi-account-multiple',
          expand : false,
          noExpand: true,
          expanded : false
        },
      ]
    },
    webinarTimeBeforeSelect() {
      return [
        { text: `${this.$t('webinar.settings.before')} 5 ${this.$t('webinar.settings.minutes')}`, value: 5 },
        { text: `${this.$t('webinar.settings.before')} 15 ${this.$t('webinar.settings.minutes')}`, value: 15 },
        { text: `${this.$t('webinar.settings.before')} 30 ${this.$t('webinar.settings.minutes')}`, value: 30 },
        { text: `${this.$t('webinar.settings.before')} 1 ${this.$t('webinar.settings.hours')}`, value: 60 },
      ]
    },
    webinarScriptStatesSelect() {
      return [
        { text: this.$t('Active'), value: true },
        { text: this.$t('NotActive'), value: false }
      ]
    },
    webinarPaymentSelect() {
      return [
        { text: this.$t('webinar.settings.payment_status_no_paid') , value: false },
        { text: this.$t('webinar.settings.payment_status_paid') , value: true },
      ]
    },
    webinarPaymentFilter() {
      return [
        {
          text: this.$t('webinar.settings.payment_status'),
          value: 'paid',
          type: 'select_multiple',
          items: this.webinarPaymentSelect
        }
      ]
    },
    memberItemsFiltered(){
      let items = this.participantsItems;

      // фильтр по оплате - платный/бесплатный
      if (this.filtersData.paid !== undefined) {
        items = items.filter(el => this.filtersData.paid ? el.paid : !el.paid);
      }
      return items
    },

    // подсчет общего времени выступления с учетом часов и минут
    finalTime() {
      const scripts = [...this.entityData.scripts.content]
      const scriptsFiltered = scripts.filter(el => !el.remove);

      if (!scriptsFiltered.length) {
        return 0
      }

      const generalTime = scriptsFiltered.reduce((accum, curValue) => accum + curValue.duration, 0);
      const hours = Math.floor(generalTime/60);
      const minutes = generalTime%60;
      let timeResult = '';

      if (generalTime > 59) {
        timeResult += `${hours} ${this.$t('webinar.settings.hours_short')}`

        if (minutes !== 0) {
          timeResult += ` ${minutes} ${this.$t('webinar.settings.minutes_short')}`
        }

      } else {
        timeResult += `${generalTime} ${this.$t('webinar.settings.minutes_short')}`
      }
      return timeResult
    },

    // переделываю массив менеджеров, чтобы он подходил по формату для таблицы
    managers() {
      let managers = this.managersList;
      let items = [];
      managers.flat().forEach(el => {
        items.push({ name: el.text, uuid: el.value, email: 'email@gmail.com', phone: '+3801234567' })
      });
      return items
    },

    // валидация первого шага создания вебинара
    isValidWebinarInfo() {
      const { name, date, time, paid, price } = this.entityData;

      if (!name || name.trim() === '' || !date || !time ) {
        return false;
      }

      if (paid && !price) {
        return false
      }

      if (this.dateError) {
        return false
      }

      return true;
    },

    // создание массива доступных действий с выбранными участниками
    multipleActionsSelect() {
      let items = [
        {
          text: 'Delete',
          icon: 'mdi-delete-outline',
          action: () => {
            this.buttonGroupValue === 'participants'
              ? this.removeParticipants(this.selectedParticipants)
              : this.removeModerators(this.selectedModerators)
          }
        },
      ]
      if (this.entityData.paid && this.buttonGroupValue === 'participants') {
        items.unshift(
    {
            text: 'webinar.settings.payment_status_paid',
            icon: 'mdi-currency-usd',
            action: () => { this.editPaymentStatus(true) }
          },
          {
            text: 'webinar.settings.payment_status_no_paid',
            icon: 'mdi-currency-usd-off',
            action: () => { this.editPaymentStatus(false) }
          })
      }
      return items
    },
  },
  methods: {
    ...mapActions('webinars', ['EDIT_WEBINAR', 'ADD_WEBINAR', 'GET_ONE_WEBINAR', 'GET_ALL_MANAGERS', 'ADD_PARTICIPANT', 'GET_PARTICIPANTS', 'DELETE_PARTICIPANT', 'EDIT_PARTICIPANT_PAYMENT_STATUS']),
    async editPaymentStatus(payment) {

      const users = [];
      this.selectedParticipants.forEach(el => {
        users.push({
          uuid: el,
          manager: false,
          paid: payment,
          participated: true
        })
      })

      const result = await this.EDIT_PARTICIPANT_PAYMENT_STATUS({ uuid: this.createdWebinar.uuid, users: users})

      if (!result) {
        this.ERROR();
        return
      }

      const items = [...this.participantsItems]
      const newItems = items.map(el => this.selectedParticipants.includes(el.uuid) ? {...el, paid: payment } : el )

      this.participantsItems = newItems;
      this.participantsItems = this.COPY(this.participantsItems)

      this.selectedParticipants = []

    },
    async savePaymentChanges(item) {
      const users = {
          uuid: item.uuid,
          manager: false,
          paid: item.paid,
          participated: true
      }

      const result = await this.EDIT_PARTICIPANT_PAYMENT_STATUS({ uuid: this.createdWebinar.uuid, users: [users]})

      if (!result) {
        this.ERROR();
      }
    },

    openCreateModeratorDialog() {
      this.displayCreateModeratorDialog = true;
      this.stepDialog = 1;
    },
    openImportMembersDialog() {
      this.displayImportMembersDialog = true;
      this.stepDialog = 1;
    },
    openCreateMemberDialog() {

      if (this.displayAddMembersFromList) {
        this.displayAddMembersFromList = false;
      }
      this.displayCreateMemberDialog = true;
      this.entityDataCreateMember = {
        activity_status: 'active',
        registration_date: this.countDateCreateMember()
      }
    },
    openAddMembersFromListDialog() {
      this.displayAddMembersFromList = true;
    },

    countDateCreateMember(){

      const currentDate = new Date();

      const day = String(currentDate.getDate()).padStart(2, '0');
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const year = currentDate.getFullYear();

      return `${year}-${month}-${day}`;
    },
    openAddScripts() {
      this.isAddScript = true;
      this.entityData.scripts.content.push({ text: ``, duration: 10 });
    },

    // действия с таблицей участников
    async sendAddMemberForm(usersList = []) {
      if (usersList.length) {
        const newParticipant = {
          webinar: this.createdWebinar.uuid,
          users: this.entityDataCreateMember.users,
          manager: false,
          paid: this.entityDataCreateMember.paid
        }

        const result = await this.ADD_PARTICIPANT(newParticipant);

        if (!result) {
          this.ERROR();
          return
        }
        this.participantsItems.push(...usersList);

      } else {
        const newParticipant = {
          webinar: this.createdWebinar.uuid,
          users: this.entityDataCreateMember.users ? this.entityDataCreateMember.users : [this.entityDataCreateMember],
          manager: false,
          paid: this.entityDataCreateMember.paid
        }

        const result = await this.ADD_PARTICIPANT(newParticipant);

        if (!result) {
          this.ERROR();
          return
        }

        this.participantsItems.push(result);
      }

      this.notify(this.$t('webinar.notify.add_participant'), 'success', 'mdi-check-circle-outline');
      this.displayCreateMemberDialog = false;
    },

    async removeParticipants(item) {
      await this.removeItem(item);

      const itemsFiltered = this.participantsItems.filter(el => item.uuid ? !item.uuid.includes(el.uuid) : !item.includes(el.uuid));

      this.participantsItems = itemsFiltered;

      this.participantsItems = this.COPY(this.participantsItems);
      this.notify(this.$t('webinar.notify.remove_participant'), 'success', 'mdi-check-circle-outline');

      this.selectedParticipants = [];

    },

    async removeItem(item) {
      const result = await this.DELETE_PARTICIPANT( { uuid: this.createdWebinar.uuid, participants: item.uuid ? [item.uuid] : item } )

      if (!result) {
        this.ERROR('no result');
        return
      }

      return result
    },

    async removeModerators(item) {
      await this.removeItem(item);

      const itemsFiltered = this.moderatorItems.filter(el => item.uuid ? !item.uuid.includes(el.uuid) : !item.includes(el.uuid));

      this.moderatorItems = itemsFiltered;

      this.moderatorItems = this.COPY(this.moderatorItems)
      this.notify(this.$t('webinar.notify.remove_moderator'), 'success', 'mdi-check-circle-outline');

      this.selectedModerators = []
    },

    // действия со сценариями
    openScriptDialog(expand) {
      expand()
    },

    // открыть/свернуть дополнительные настройки вебинара
    showHideActions() {
      this.showHideAdditionalSettings = !this.showHideAdditionalSettings;

      if (!this.showHideAdditionalSettings) {
        setTimeout(() => {
          this.scrollToBottom();
        }, 400)
      }
    },

    // сохраняю вебинар или закрываю диалоговое окно (в зависимости от шага)
    async nextPublishAction() {
      if (this.isValidWebinarInfo && this.step === 1) {
        await this.addEditWebinar();
        await this.getAllManagers();
        await this.getParticipants();
        this.$emit('set-step', 2);
      } else {
        this.entityData.status = 'planned';
        await this.editWebinar();
        this.notify(this.$t('webinar.notify.publish_webinar'), 'success', 'mdi-check-circle-outline');
        this.$emit('set-display-dialog', false);
        this.$router.push(`webinar/${this.createdWebinar.uuid}`)
      }
    },

    async saveEditedWebinar() {
      if (this.isValidWebinarInfo && this.step === 1) {
        await this.editWebinar();
        await this.getAllManagers();
        this.$emit('set-step', 2);
      } else {
        this.$emit('edit-webinar', this.entityData);
        this.$emit('set-display-dialog', false);
      }
    },

    async getParticipants() {
      this.loading = true;
      const result = await this.GET_PARTICIPANTS( { uuid: this.createdWebinar.uuid, limit: this.limit } );

      this.loading = false;
      if (!result) {
        this.ERROR();
        return
      }

      this.participantsItems = result.items

    },

    async getAllManagers() {
      const result = await this.GET_ALL_MANAGERS();
      if (!result) {
        this.ERROR();
        return
      }

      this.managersList.push(result)
    },

    goBack() {
      this.step === 1
        ? this.$emit('set-display-dialog', false)
        : this.$emit('set-step', 1);
    },

    saveDraft() {
      this.$emit('set-display-dialog', false);
    },

    async saveNewMemberListChanges(event, userList) {
      this.entityDataCreateMember = {
        users: event.selectedUsers,
        paid: event.paid,
      }
      const newUsersList = userList.map(el => ({ ...el, paid: event.paid }))
      await this.sendAddMemberForm(newUsersList)

      this.displayAddMembersFromList = false
    },



    async saveNewModeratorListChanges(event, moderatorsList) {

      const newModerator = {
        webinar: this.createdWebinar.uuid,
        users: event.selectedUsers,
        manager: true,
        paid: false
      }

      const result = await this.ADD_PARTICIPANT(newModerator);

      if (!result) {
        this.ERROR();
        return
      }

      this.moderatorItems.push(...moderatorsList);

      this.notify(this.$t('webinar.notify.add_manager'), 'success', 'mdi-check-circle-outline');
      this.displayCreateModeratorDialog = false;
    },

    saveDate() {
      const startDate = this.$time(`${this.entityData.date} ${this.entityData.time}`);

      if (startDate <= this.$time() ) {
        this.dateError = true;
        this.ERROR(this.$t('webinar.notify.time_error'))
        return
      }

      this.entityData.date_start = startDate.YMDT()

    },
    async addEditWebinar() {
      delete this.entityData.date;
      delete this.entityData.time;

      if (!this.entityData.uuid) {
        // добавляю вебинар
        console.log('add webinar obj ----', this.entityData)
        const result = await this.ADD_WEBINAR(this.entityData);

        if (!result) {
          this.ERROR();
          return
        }
        console.log('add webinar result ----', result)

        this.createdWebinar = result;
        this.$emit('add-new-webinar', result);
      } else {
          await this.editWebinar();
      }
    },
    async editWebinar() {
      const elem = this.entityData.scripts.content.filter(el => !el.remove)
      if (!elem.length) {
        this.entityData.scripts_enabled = false
      }

      // редактирую вебинар
      this.createdWebinar = { ...this.createdWebinar , ...this.entityData }

      // const someVal = { ...this.createdWebinar , ...this.entityData }
      // console.log('someVal', someVal)
      console.log('this.createdWebinar' , this.createdWebinar)


      const result = await this.EDIT_WEBINAR(this.step === 2 ? { uuid: this.createdWebinar.uuid, status: 'planned'} : this.createdWebinar);

      if (!result) {
        this.ERROR();
        return
      }

      console.log('this.createdWebinar result' , result)
      this.createdWebinar = result
      this.$emit('edit-webinar', result);

    },
    // Technical

    // функция прокрутки страницы до конца при октрытии доп.настроек
    scrollToBottom() {
      this.contentContainer.scrollTo({
        top: this.contentContainer.scrollHeight,
        behavior: 'smooth'
      });
    },

    convertValueToText(field, items) {
      const element = items.find(el => el.value === field);

      if (!element) {
        this.ERROR();
        return
      }

      return element.text
    },

    async initPage() {
      this.loading = true
      const result = await this.GET_ONE_WEBINAR( { uuid: this.uuid } );

      this.loading = false;

      if (!result) {
        this.ERROR(result);
        return
      }

      this.entityData.date = !result.date_start ? '' : result.date_start.split(' ')[0];
      this.entityData.time = !result.date_start ? '' : result.date_start.split(' ')[1];

      this.entityData = {...this.entityData, ...result}

      if (result.scripts.content.length) {
        this.isAddScript = true
      }
      this.saveDate()
    },

  },

  watch: {
    entityData: {
      handler() {
        this.saveDate()
      },
      deep: true
    },
    displayDialog(val) {
      if (val) {
        this.entityData = {
          scripts_enabled: false,
          paid: false,
          access: true,
          scripts: {
            content: []
          },
          time_before: 15,
          lang: 'ua',
          show_participants: false,
          status: 'draft'
        }
      }
      if(val && !this.isNewWebinar) {
        this.initPage()
      }
    },
    deep: true,
    dateError() {
      this.saveDate()
    }
  }


}
</script>

<style scoped>

</style>
