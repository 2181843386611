<template>
  <v-sheet
      v-if="display"
      class="webinarDialog"
      v-model="display"
      :title="$t('Title')"
      @save="$emit('save')"
      :color="wsDARKER"
  >
    <div class="fill-height d-flex flex-column" style="position: relative">

      <!-- Header-->
      <ws-webinar-dialog-header
          @close="display = false"
          @start="handleStartStream"
      />

      <!-- Content-->
      <div style="height: 100%; min-height: 35px; background-color: var(--wsDARKER)"
           class="py-6"

      >
        <div class="fill-height d-flex flex-row"  >

          <!-- Sources Sidebar-->
          <div style="width: 224px; transition: all 0.5s ease;"
               :style="`width : ${!DISPLAY_VIDEO_INPUT_SIDEBAR ? 224 : 0}px`"
               class="overflow-auto pa-4" >
            <stream-sources-sidebar
                v-if="!DISPLAY_VIDEO_INPUT_SIDEBAR"
            />
          </div>

          <!-- Video Content -->
          <div class="flex-grow-1">
            <webinar-video-view />
          </div>

          <!-- Right Sidebar -->
          <div class="px-6" >
            <ws-right-sidebar />
          </div>
        </div>
      </div>

      <!-- DIALOGS-->
      <!--   Webinar preview dialog   -->
      <ws-webinar-preview-dialog
          v-if="displayPreviewDialog"
          v-model="displayPreviewDialog"
          @start="handlePreviewStart"
          @exit-webinar="display = false"
      />
      <!--   Final Countdown before Start   -->
      <ws-countdown-before-start
          v-if="displayFinalCountDown"
          v-model="displayFinalCountDown"
      />

    </div>


  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";
import webinars from "@/modules/webinars/mixins/webinars";

import WsRightSidebar from "@modules/webinars/components/Webinar/admin/room/sidebars/wsRightSidebar.vue";
import webinarVideoView from "@modules/webinars/components/Webinar/admin/room/stream/webinarVideoView.vue"
import WsWebinarDialogHeader from "@modules/webinars/components/Webinar/admin/room/UI/header/wsWebinarDialogHeader.vue";
import WsWebinarPreviewDialog from "@modules/webinars/components/Webinar/admin/room/UI/wsWebinarPreviewDialog.vue"
import WsCountdownBeforeStart from "@modules/webinars/components/Webinar/admin/room/UI/wsCountdownBeforeStart.vue";
import StreamSourcesSidebar from "@modules/webinars/components/Webinar/admin/room/sidebars/streamSourcesSidebar.vue"

export default {
  name: "WebinarWindowNew",
  mixins: [webinars],
  components : {
    StreamSourcesSidebar,
    WsWebinarDialogHeader,
    WsWebinarPreviewDialog,
    WsCountdownBeforeStart,
    webinarVideoView,
    WsRightSidebar
  },
  props : {
    value : {
      type : Boolean
    },
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      display : false,
      displayPreviewDialog : true,
      displayFinalCountDown : false
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('webinars', [
        'GET_ONE_WEBINAR',
        'GET_PARTICIPANTS'
    ]),

    handlePreviewStart(sources) {
      this.$store.state.stream.videoSources.push({
        value : this.SELECTED_CAMERA,
        name : this.$store.state.auth.userName,
        stream : sources.video,
        isLocal : true,
        active : true  ,
        main : true,
        deviceId : this.SELECTED_CAMERA
      })
      this.$store.state.stream.audioSources.push({
        value : this.SELECTED_MICROPHONE,
        name : this.$store.state.auth.userName,
        stream : sources.audio,
        isLocal : true,
        active : true  ,
        main : true,
        deviceId : this.SELECTED_MICROPHONE,
        isAdmin : true
      })
    },

    handleStartStream() {
      this.displayFinalCountDown = true
    },

    async getWebinarDetails() {
      const result = await this.GET_ONE_WEBINAR({ uuid: this.uuid } );

      if (!result) {
        this.ERROR();
        return
      }

      this.$store.state.webinars.webinar = await result;
    },

    async getWebinarParticipants() {

      let data = { uuid: this.uuid , limit : 9999  }

      const result = await this.GET_PARTICIPANTS(data);

      if (!result) {
        this.ERROR();
        return
      }

      this.$store.state.webinars.participants = result.items || []

    },

  },
  async mounted() {

    if ( this.value ) {
      this.display = this.value
    }

    this.getWebinarDetails()
    this.getWebinarParticipants()
  },
  beforeDestroy() {
    this.$store.commit('stream/FLUSH_VARIABLES')
  }
}
</script>

<style scoped>
.webinarDialog {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

</style>
